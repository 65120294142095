<template>
  <v-row justify="center">
    <v-dialog
      v-model="showForm"
      width="600px"
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <v-container class="pa-0">
        <v-card class="pb-10">
          <v-system-bar
            height="47"
            :color="
              form.status == 'Active' || empty(form.status)
                ? '#aeddff'
                : '#ff5252'
            "
          >
            <v-icon @click="close">mdi-close</v-icon>
            <h3
              :class="form.status == 'Active' ? 'black--text' : 'white--text'"
            >
              {{ title }}
            </h3>
            <span
              class="ml-3 mt-1 white--text blink"
              v-if="form.status == 'Disabled'"
            >
              RECORD IS DISABLED
            </span>
            <v-spacer></v-spacer>
            <v-btn
              title
              color="primary"
              v-if="allownew && view != 'new'"
              @click="newForm"
              elevation="0"
              class="mr-2"
            >
              <v-icon color="white">mdi-plus</v-icon>
              New
            </v-btn>
            <v-btn
              title
              color="warning"
              v-if="allowEdit && !empty(form.id) && view == 'view'"
              @click="view = 'edit'"
              elevation="0"
            >
              <v-icon color="white">mdi-pencil</v-icon>
              Edit
            </v-btn>
            <v-btn
              title
              color="warning"
              v-if="allowEdit && view != 'view'"
              @click="cancelForm"
              elevation="0"
            >
              <v-icon color="white">mdi-cancel</v-icon>
              Cancel
            </v-btn>
            <v-btn
              title
              color="success"
              class="ml-2"
              v-if="
                (allowEdit || allownew) && (view == 'new' || view == 'edit')
              "
              @click="saveForm"
              elevation="0"
            >
              <v-icon color="white">mdi-content-save</v-icon>
              Save
            </v-btn>
            <v-btn
              title
              color="error"
              class="ml-2"
              v-if="
                allowDisable &&
                !empty(form.id) &&
                form.status == 'Active' &&
                view == 'view'
              "
              @click="disableRecord(false)"
              elevation="0"
            >
              <v-icon color="white">mdi-close-circle</v-icon>
              Disable
            </v-btn>
            <v-btn
              title
              color="success"
              class="ml-2"
              v-if="
                allowDisable &&
                !empty(form.id) &&
                form.status == 'Disabled' &&
                view == 'view'
              "
              @click="disableRecord(true)"
              elevation="0"
            >
              <v-icon color="white">mdi-close-circle</v-icon>
              Enable
            </v-btn>
          </v-system-bar>
          <v-divider />
          <v-card-text>
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              label-width="120px"
              label-position="top"
              class="demo-ruleForm"
            >
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <el-form-item label="English Name" prop="name">
                    <el-input
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.name"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <el-form-item
                    label="Dhivehi Name"
                    prop="dhivehi_name"
                    style="text-align: right"
                  >
                    <span slot="label" class="thaana">ދިވެހި ނަން</span>
                    <dhivehi-field
                      v-model="form.dhivehi_name"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-sm-12">
                  <el-form-item label="Country" prop="countrys_id">
                    <select-list
                      v-model="form.countrys_id"
                      editor="geo/countrys"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </v-card-text>
          <!--<v-divider />-->
        </v-card>
        <v-overlay :value="loading" :opacity="0.7">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-container>
    </v-dialog>
  </v-row>
</template>
<script>
import Vue from "vue";
import {
  Button,
  Form,
  FormItem,
  Input,
  InputNumber,
  Select,
  Option,
} from "element-ui";
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Button);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Select);
Vue.use(Option);
import Swal from "sweetalert2";
import DhivehiField from "../../custom/DhivehiField.vue";
import SelectList from "@/components/custom/SelectList.vue";
export default {
  created() {},
  watch: {
    show: function () {
      this.showForm = this.show;
      if (!this.empty(this.perm)) this.permissions = this.perm;
      if (this.showForm) {
        if (this.empty(this.data)) {
          this.newForm();
        } else {
          this.view = "view";
        }
      }
    },
    data: function () {
      if (!this.empty(this.data)) {
        this.getRecord();
      }
    },
    perm: function () {
      this.permissions = this.perm;
    },
  },
  props: {
    data: null,
    show: {
      type: Boolean,
      default: false,
    },
    perm: null,
    popup: {
      type: Boolean,
      default: false,
    },
  },
  newd() {},
  components: {
    DhivehiField,
    SelectList,
  },
  data() {
    return {
      end_point: "events/event-locations/",
      title: "Event Locations",
      form: {
        id: "",
        name: "",
        dhivehi_name: "",
        countrys_id: null,
        atolls_id: null,
        islands_id: null,
        road: null,
        ward: null,
        lat: null,
        lng: null,
        description: null,
        users_id: "",
        status_id: "",
        newd_at: "",
        updated_at: "",
      },
      view: "view",
      rules: {
        name: [
          { required: true, message: "Please enter Name", trigger: "blur" },
        ],
        countrys_id: [
          { required: true, message: "Please select Country", trigger: "blur" },
        ],
      },
      permissions: [],
      loading: false,
      showForm: false,
    };
  },
  methods: {
    saveForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    submit() {
      this.loading = true;
      var api = "create";
      if (!this.empty(this.form.id)) api = "update";
      this.api(this.end_point + api, this.form)
        .then((response) => {
          this.loading = false;
          this.form = response.data;
          this.view = "view";
          this.showInfo("Record Saved successfully");
          this.$emit("updated");
          if (this.popup) this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    disableRecord(enable) {
      var title = "Do you want Enable the record?";
      var text = "Once enabled users can view the record";
      var button = "Yes, enable it!";
      if (!enable) {
        title = "Do you want to disabled the record?";
        text = "Once disabled users wont be able to view the records";
        button = "Yes, disable it!";
      }
      Swal.fire({
        title: title,
        text: text,
        type: "question",
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonColor: "#5daf34",
        cancelButtonColor: "#d33",
        showCancelButton: true,
        confirmButtonText: button,
      }).then((result) => {
        if (result.value) {
          this.change_status(enable);
        }
      });
    },
    change_status(enable) {
      var api = "enable";
      if (!enable) api = "disable";
      this.loading = true;
      this.api(this.end_point + api, this.form)
        .then((response) => {
          this.loading = false;
          this.form = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    newForm() {
      this.form = {
        id: "",
        name: "",
        dhivehi_name: "",
        countrys_id: null,
        atolls_id: null,
        islands_id: null,
        road: null,
        ward: null,
        lat: null,
        lng: null,
        description: null,
        users_id: "",
        status_id: "",
        newd_at: "",
        updated_at: "",
      };
      this.view = "new";
    },
    getRecord() {
      let f = {
        id: this.data.id,
      };
      this.loading = true;
      this.api(this.end_point + "record", f)
        .then((response) => {
          this.loading = false;
          this.form = response.data;
          this.permissions = response.permissions;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit("close");
    },
    cancelForm() {
      this.view = "view";
      if (this.popup) this.close();
    },
  },
  computed: {
    allownew() {
      return (
        this.permissions.filter((e) => e.permission == "Create").length > 0
      );
    },
    allowEdit() {
      return this.permissions.filter((e) => e.permission == "Edit").length > 0;
    },
    allowDisable() {
      return (
        this.permissions.filter((e) => e.permission == "Disable").length > 0
      );
    },
  },
  name: "EventLocationsForm",
};
</script>
