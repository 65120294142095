var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"width":"600px","hide-overlay":"","persistent":"","transition":"dialog-bottom-transition"},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('v-container',{staticClass:"pa-0"},[_c('v-card',{staticClass:"pb-10"},[_c('v-system-bar',{attrs:{"height":"47","color":_vm.form.status == 'Active' || _vm.empty(_vm.form.status)
              ? '#aeddff'
              : '#ff5252'}},[_c('v-icon',{on:{"click":_vm.close}},[_vm._v("mdi-close")]),_c('h3',{class:_vm.form.status == 'Active' ? 'black--text' : 'white--text'},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.form.status == 'Disabled')?_c('span',{staticClass:"ml-3 mt-1 white--text blink"},[_vm._v(" RECORD IS DISABLED ")]):_vm._e(),_c('v-spacer'),(_vm.allownew && _vm.view != 'new')?_c('v-btn',{staticClass:"mr-2",attrs:{"title":"","color":"primary","elevation":"0"},on:{"click":_vm.newForm}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-plus")]),_vm._v(" New ")],1):_vm._e(),(_vm.allowEdit && !_vm.empty(_vm.form.id) && _vm.view == 'view')?_c('v-btn',{attrs:{"title":"","color":"warning","elevation":"0"},on:{"click":function($event){_vm.view = 'edit'}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-pencil")]),_vm._v(" Edit ")],1):_vm._e(),(_vm.allowEdit && _vm.view != 'view')?_c('v-btn',{attrs:{"title":"","color":"warning","elevation":"0"},on:{"click":_vm.cancelForm}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-cancel")]),_vm._v(" Cancel ")],1):_vm._e(),(
              (_vm.allowEdit || _vm.allownew) && (_vm.view == 'new' || _vm.view == 'edit')
            )?_c('v-btn',{staticClass:"ml-2",attrs:{"title":"","color":"success","elevation":"0"},on:{"click":_vm.saveForm}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-content-save")]),_vm._v(" Save ")],1):_vm._e(),(
              _vm.allowDisable &&
              !_vm.empty(_vm.form.id) &&
              _vm.form.status == 'Active' &&
              _vm.view == 'view'
            )?_c('v-btn',{staticClass:"ml-2",attrs:{"title":"","color":"error","elevation":"0"},on:{"click":function($event){return _vm.disableRecord(false)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close-circle")]),_vm._v(" Disable ")],1):_vm._e(),(
              _vm.allowDisable &&
              !_vm.empty(_vm.form.id) &&
              _vm.form.status == 'Disabled' &&
              _vm.view == 'view'
            )?_c('v-btn',{staticClass:"ml-2",attrs:{"title":"","color":"success","elevation":"0"},on:{"click":function($event){return _vm.disableRecord(true)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close-circle")]),_vm._v(" Enable ")],1):_vm._e()],1),_c('v-divider'),_c('v-card-text',[_c('el-form',{ref:"form",staticClass:"demo-ruleForm",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"120px","label-position":"top"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-sm-12"},[_c('el-form-item',{attrs:{"label":"English Name","prop":"name"}},[_c('el-input',{attrs:{"size":"mini","disabled":_vm.view != 'new' && _vm.view != 'edit'},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1),_c('div',{staticClass:"col-lg-6 col-sm-12"},[_c('el-form-item',{staticStyle:{"text-align":"right"},attrs:{"label":"Dhivehi Name","prop":"dhivehi_name"}},[_c('span',{staticClass:"thaana",attrs:{"slot":"label"},slot:"label"},[_vm._v("ދިވެހި ނަން")]),_c('dhivehi-field',{attrs:{"disabled":_vm.view != 'new' && _vm.view != 'edit'},model:{value:(_vm.form.dhivehi_name),callback:function ($$v) {_vm.$set(_vm.form, "dhivehi_name", $$v)},expression:"form.dhivehi_name"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-sm-12"},[_c('el-form-item',{attrs:{"label":"Country","prop":"countrys_id"}},[_c('select-list',{attrs:{"editor":"geo/countrys","disabled":_vm.view != 'new' && _vm.view != 'edit'},model:{value:(_vm.form.countrys_id),callback:function ($$v) {_vm.$set(_vm.form, "countrys_id", $$v)},expression:"form.countrys_id"}})],1)],1)])])],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading,"opacity":0.7}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }